@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

// Clean UI KIT styles
@import 'components/kit/core/style';
// Clean UI styles
@import 'components/cleanui/styles/style';
// Custom App styles
@import 'components/app/style/custom';

[data-kit-theme='default'] .ant-menu-item-selected a,
[data-kit-theme='default'] .ant-menu-item-selected a:hover {
  color: #fff;
}

.custom-table .ant-table-thead > tr > th,
.custom-table .ant-table-tbody > tr > td,
.custom-table .ant-table tfoot > tr > th,
.custom-table .ant-table tfoot > tr > td {
  position: relative;
  padding: 5px 5px; // padding: 16px 16px;
  overflow-wrap: break-word;
}

.theme-btn-style {
  background-color: #000;
  color: #fff;
}

.theme-btn-style:hover {
  background-color: #064075;
  color: #000;
  font-weight: 500;
}
